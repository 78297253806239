import Const from "@/api/const";

export default function (instance) {
  return {
    getShopHeader() {
      return instance.get("/api/v1/shopHeader/" + Const.SITE_ID + "/");
    },
    getShopFooter() {
      return instance.get("/api/v1/shopFooter/" + Const.SITE_ID + "/");
    },
    getShopPageFromPath(slug) {
      return instance.get(
        "/api/v1/shopPageFromPath/" + Const.SITE_ID + "/?value=" + slug
      );
    },
    getCityInfo() {
      return instance.post(
        "https://suggestions.dadata.ru/suggestions/api/4_1/rs/iplocate/address",
        {
          language: "ru",
        },
        {
          headers: {
            Authorization: "Token 3027d788a1f31d494c363b6dc6b301dd3b9c4f13",
          },
        }
      );
    },
    getCities(page) {
      return instance.get("/api/v1/cities/?count=5000&page_size=" + page);
    },
    getDeliveryPointsMaps() {
      return instance.get("/api/v1/deliveryPointsMaps/");
    },
    getInfoDeliveryPoint(point_id) {
      return instance.get("api/v1/pointOfIssue/" + point_id + "/");
    },
    getDeliveryFromCity(city) {
      return instance.get("/api/v1/deliveryFromCity/?value=" + city);
    },
    getDeliveryInfoFromCity(city) {
      return instance.get("/api/v1/deliveryInfoFromCity/?value=" + city);
    },
    getProductFeedback(product_id, count) {
      return instance.get(
        "api/v1/shopProductReviews/?product_id=" +
          product_id +
          "&count=" +
          count
      );
    },
    getProductQuestions(product_id) {
      return instance.get(
        "api/v1/shopProductQuestions/?product_id=" + product_id
      );
    },
    getIsOrdersOnProduct(product_id) {
      return instance.get(
        "api/v1/shopIsOrdersOnProduct/?product_id=" + product_id
      );
    },
    postAddFeedback(payload) {
      return instance.post("api/v1/shopProductAddReview/", payload);
    },
    postAddQuestion(payload) {
      return instance.post("api/v1/shopProductAddQuestion/", payload);
    },
    getCountProductsCatalog(payload) {
      return instance.get(
        "api/v1/countProductsCatalog/" + Const.SITE_ID + "/",
        { params: payload }
      );
    },
    getParamsCatalog() {
      return instance.get("api/v1/paramsCatalog/" + Const.SITE_ID + "/");
    },
    getProductsCatalog(payload) {
      return instance.get("api/v1/productsCatalog/" + Const.SITE_ID + "/", {
        params: payload,
      });
    },
    getBlogArticles() {
      return instance.get("api/v1/shopBlogArticles/?site_id=" + Const.SITE_ID);
    },
    getOrderOfBlogArticles(order_id) {
      return instance.get("api/v1/shopBlogArticles/?order_id=" + order_id);
    },
    postBlogArticleCountViews(article_id) {
      return instance.put(
        "api/v1/shopBlogArticleCountViews/" + article_id + "/"
      );
    },
    postProductCountViews(product_id) {
      return instance.put("api/v1/shopProductCountViews/" + product_id + "/");
    },
    getShopMainPage() {
      return instance.get("api/v1/shopMainPage/" + Const.SITE_ID + "/");
    },
    getShopMainPageBanner() {
      return instance.get("api/v1/shopMainPageBanner/" + Const.SITE_ID + "/");
    },
    getShopMainPageStory() {
      return instance.get("api/v1/shopMainPageStory/" + Const.SITE_ID + "/");
    },
    getShopMainPageSelection() {
      return instance.get(
        "api/v1/shopMainPageSelection/" + Const.SITE_ID + "/"
      );
    },
    getShopMainPageReview() {
      return instance.get("api/v1/shopMainPageReview/" + Const.SITE_ID + "/");
    },
    getShopProductUserPurchases() {
      return instance.get("api/v1/shopProductUserPurchases/");
    },
    getShopProductUserFavorites() {
      return instance.get("api/v1/shopProductUserFavorites/");
    },
    getShopProductUserFavorite(product_id) {
      return instance.get("api/v1/shopProductUserFavorite/" + product_id + "/");
    },
    getShopProductUserViews() {
      return instance.get("api/v1/shopProductUserViews/");
    },
    postShopProductUserView(payload) {
      return instance.post("api/v1/shopProductUserView/", payload);
    },
    postShopProductUserFavorite(payload) {
      return instance.post("api/v1/shopProductUserFavorite/", payload);
    },
    getShopProductUserBasket(product_id) {
      return instance.get("api/v1/shopProductUserBasket/" + product_id + "/");
    },
    postShopProductUserBasket(payload) {
      return instance.post("api/v1/shopProductUserBasket/", payload);
    },
    getShopProductsInfo(product_id) {
      return instance.get("api/v1/productsInfo/?product_id=" + product_id);
    },
    getShopProductUserBasketCount() {
      return instance.get("api/v1/shopProductUserBasketCount/");
    },
    getShopProductUserBaskets() {
      return instance.get("api/v1/shopProductUserBasket/");
    },
    deleteShopProductUserBasketClear() {
      return instance.delete("api/v1/shopProductUserBasketClear/");
    },
    postShopCreateOrder(payload) {
      return instance.post("api/v1/shopCreateOrder/", payload);
    },
    postShopCreateReturn(payload) {
      return instance.post("api/v1/shopCreateReturn/", payload);
    },
    postShopUpdateReturn(payload) {
      return instance.post("api/v1/shopUpdateReturn/", payload);
    },
    postShopConfirmReturn(return_id) {
      return instance.post("api/v1/shopConfirmReturn/" + return_id + "/");
    },
    postShopGeneratorPaymentOrder(order_id) {
      return instance.post(
        "api/v1/shopGeneratorPaymentOrder/" + order_id + "/"
      );
    },
    postShopRepeatOrder(order_id) {
      return instance.post("api/v1/shopRepeatOrder/" + order_id + "/");
    },
    postShopCancelOrder(order_id) {
      return instance.post("api/v1/shopCancelOrder/" + order_id + "/");
    },
    getCourierServicePrice() {
      return instance.get("api/v1/courierServicePrice/?count=10000");
    },
    postShopAddPaymentCard() {
      return instance.post("api/v1/shopAddPaymentCard/");
    },
    getPromoCodeValueCheck(value) {
      return instance.get("api/v1/promoCode/" + value + "/?check=1");
    },
    getPromoCodeValue(value) {
      return instance.get("api/v1/promoCode/" + value + "/");
    },
    putShopArticleUserLike(article_id) {
      return instance.put("api/v1/shopArticleUserLike/" + article_id + "/");
    },
    putShopProductReviewUserLike(review_id) {
      return instance.put(
        "api/v1/shopProductReviewUserLike/" + review_id + "/"
      );
    },
    postShopTrackerClick(payload) {
      return instance.post("api/v1/shopTrackerClick/", payload);
    },
    postShopAutoTracker(payload) {
      return instance.post("api/v1/shopAutoTracker/", payload);
    },
    postShopTrackerClickBasket(payload) {
      return instance.post("api/v1/shopTrackerClickBasket/", payload);
    },
    postShopSearchSession(payload) {
      return instance.post("api/v1/shopSearchSession/", payload);
    },
    postShopSearchSessionProducts(payload) {
      return instance.post("api/v1/shopSearchSessionProducts/", payload);
    },
    postShopSearchSessionClickProduct(payload) {
      return instance.post("api/v1/shopSearchSessionClickProduct/", payload);
    },
    postShopSearchSessionBasket(payload) {
      return instance.post("api/v1/shopSearchSessionBasket/", payload);
    },
    getShopSearchPopularQueries(value) {
      return instance.get(
        "api/v1/shopSearchPopularQueries/?user_hash=" + value
      );
    },
    getShopSearchPopularProducts(value) {
      return instance.get("api/v1/shopSearchPopularProducts/?count=" + value);
    },
    deleteShopSearchStoryQuery(payload) {
      return instance.post("api/v1/shopSearchStoryQuery/", payload);
    },
    getShopSearchPromptQuery(value) {
      return instance.get("api/v1/shopSearchPromptQuery/?value=" + value);
    },
    getShopSearchPromptProduct(value) {
      return instance.get("api/v1/shopSearchPromptProduct/?value=" + value);
    },
    getCheckPackageBasket(product_id) {
      return instance.get(
        "api/v1/shopCheckPackageBasket/?product_id=" + product_id
      );
    },
    postShopProductStatistics(payload) {
      return instance.post("api/v1/shopProductStatistics/", payload);
    },
    getPromotionFromPath(slug) {
      return instance.get("/api/v1/promotionFromPath/?value=" + slug);
    },
    getPromotionProducts(payload) {
      return instance.get("/api/v1/promotionProducts/", {
        params: payload,
      });
    },
    getProductGiftCardsNominals() {
      return instance.get("/api/v1/productGiftCardsNominals");
    },
    getGiftCardFromPath(slug) {
      return instance.get("/api/v1/giftCardFromPath/?value=" + slug);
    },
    getGiftCardView(payload) {
      return instance.post("/api/v1/giftCardView/", payload);
    },
  };
}
