<template>
  <HeaderBlock />
  <NotificationContinueActionBlock v-if="false" :authToken="authToken" />
  <section class="main-section">
    <div class="banners-block">
      <swiper
        class="swiper"
        :modules="modules"
        :slides-per-view="bannerSlidesPerView"
        :initialSlide="bannerInitialSlide"
        :space-between="10"
        :loop="true"
        :navigation="banners.length > 1"
        :allow-touch-move="valueAllowTouchMove"
        :style="{
          '--swiper-navigation-color': '#5E5E5E',
          '--swiper-navigation-size': '32px',
        }"
        @slideChange="changeValueDelay"
        @mouseover="startPause = 1"
        @mouseleave="startPause = 0"
      >
        <swiper-slide v-for="(item, index) in banners" :key="item">
          <a
            :href="item.link"
            v-bind:data-index="index"
            :class="'main-banner-' + item.id"
          >
            <img :src="item.image" />
          </a>
        </swiper-slide>
      </swiper>
    </div>
    <h1>{{ page.headline_h1 }}</h1>
    <div class="stories-block">
      <swiper
        class="swiper"
        :modules="modules"
        :space-between="20"
        :slides-per-view="storiesBlockSlidesPerView"
        :loop="false"
        :navigation="stories.length > storiesBlockSlidesPerView"
        :allow-touch-move="valueAllowTouchMoveStories"
        :style="{
          '--swiper-navigation-color': '#5E5E5E',
          '--swiper-navigation-size': '32px',
        }"
      >
        <swiper-slide v-for="(item, index) in stories" :key="item">
          <div
            class="story-item"
            @click="(isOpenPopupStories = true), (initialSlide = index)"
            :class="{ viewed: item.isViewed }"
          >
            <span
              class="image"
              :style="{
                background: 'url(' + item.preview.image_object_middle + ')',
              }"
            ></span>
            <span class="text">{{ item.name }}</span>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div v-for="item in selections" :key="item" class="selection-item">
      <h2>{{ item.headline }}</h2>
      <div class="products-items">
        <ProductItemBlock
          v-for="item in item.products"
          :product="item"
          :key="item"
          :isFavorite="true"
          :isBasket="true"
          :type="'selection'"
          :sourcePage="4"
        />
      </div>
      <span class="lot-loading-page">
        <a
          v-if="item.button_page && item.button_page.type === 3"
          :href="'/catalog/' + item.button_page.url"
        >
          {{ item.name }}
        </a>
        <a
          v-else-if="item.button_page && item.button_page.type === 4"
          :href="'/blog/' + item.button_page.url"
        >
          {{ item.name }}
        </a>
        <a
          v-else-if="item.button_page && item.button_page.type === 5"
          :href="'/blog/' + item.button_page.url"
        >
          {{ item.name }}
        </a>
        <a
          v-else-if="item.button_page && item.button_page.type === 6"
          :href="'/catalog/' + item.button_page.url"
        >
          {{ item.name }}
        </a>
        <a v-else-if="item.button_page" :href="item.button_page.url">
          {{ item.name }}
        </a>
        <a v-else :href="item.button_external_reference">{{ item.name }}</a>
      </span>
    </div>
    <div v-if="feedbacks.length > 0" class="feedback-block">
      <h2>{{ page.reviews_headline }}</h2>
      <div class="feedback-list">
        <div class="feedback-items">
          <div v-for="item in feedbacks" :key="item" class="feedback-item">
            <MainFeedbackBlock :feedback="item" />
            <span class="full" @click="item.isOpen = true">
              {{ locale.TEXTS.feedbackMore }}
            </span>
          </div>
        </div>
        <swiper
          class="swiper"
          :modules="modules"
          :space-between="5"
          :slides-per-view="feedbacksSlidesPerView"
          :allow-touch-move="true"
          :loop="true"
        >
          <swiper-slide v-for="item in feedbacks" :key="item">
            <div class="feedback-item">
              <MainFeedbackBlock :feedback="item" />
              <span class="full" @click="item.isOpen = true">
                {{ locale.TEXTS.feedbackMore }}
              </span>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="articles-block">
      <h2>{{ page.blog_headline }}</h2>
      <div class="articles-items">
        <div v-for="item in articles" :key="item" class="article-item">
          <ArticleItemBlock :item="item" />
        </div>
      </div>
      <span class="lot-loading-page">
        <a href="/blog">
          {{ locale.TEXTS.hrefToBlog }}
        </a>
      </span>
    </div>
    <div v-for="item in feedbacks" :key="item">
      <div class="feedback-item" v-if="item.isOpen">
        <div class="main-feedback-popup shop-popup">
          <div class="content">
            <img
              @click="item.isOpen = false"
              src="@/assets/images/shop-close.svg"
              class="close"
            />
            <div class="info">
              <span class="image">
                <img :src="item.image.image_object" />
              </span>
              <span class="name">
                <span>{{ item.name }}</span>
                <span>{{ item.position }}</span>
              </span>
            </div>
            <p>{{ item.full_comment }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div v-if="isOpenPopupStories" class="stories-popup shop-popup unselectable">
    <div class="content">
      <span class="close" @click="isOpenPopupStories = null">
        <img src="@/assets/images/shop-close-white.svg" />
      </span>
      <span v-if="isVideo && isMuted" class="volume" @click="isMuted = false">
        <img src="@/assets/images/shop-mute.svg" />
        <span>{{ locale.TEXTS.withoutSound }}</span>
      </span>
      <span v-else-if="isVideo" class="volume" @click="isMuted = true">
        <img src="@/assets/images/shop-volume.svg" />
        <span>{{ locale.TEXTS.withSound }}</span>
      </span>
      <swiper
        class="swiper"
        :modules="modules"
        :initialSlide="initialSlide"
        :slides-per-view="storiesSlidesPerView"
        :space-between="60"
        :centered-slides="true"
        :loop="false"
        :navigation="true"
        :allow-touch-move="valueAllowTouchMoveStories"
        @slideChange="changeSlideStories"
      >
        <swiper-slide v-for="(item, index) in stories" :key="item">
          <div class="story-item" :data-index="index">
            <span
              class="image"
              :style="{
                background: 'url(' + item.preview.image_object_middle + ')',
              }"
            ></span>
            <span class="text">{{ item.name }}</span>
            <div class="tabs">
              <div v-for="element in item.elements" :key="element">
                <span></span>
              </div>
            </div>
            <div
              class="elements"
              @mousedown="startPauseStoryElement"
              @mouseup="(event) => stopPauseStoryElement(event)"
            ></div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
  <FooterBlock />
  <AuthRegBlock
    v-if="isOpenAuth && locale.LOCALE === 'ru'"
    :authText="authText"
    :nextAfterAuth="nextAfterAuth"
  />
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import HeaderBlock from "@/components/HeaderBlock.vue";
import NotificationContinueActionBlock from "@/components/NotificationContinueActionBlock.vue";
import FooterBlock from "@/components/FooterBlock.vue";
import AuthRegBlock from "@/components/AuthRegBlock.vue";
import ProductItemBlock from "@/components/ProductItemBlock.vue";
import MainFeedbackBlock from "@/components/MainFeedbackBlock.vue";
import ArticleItemBlock from "@/components/ArticleItemBlock.vue";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css";
import "swiper/css/navigation";
import api from "@/api";
import Const from "@/api/const";
import Locale from "@/api/locale";

export default {
  mixins: [mainMixin],
  components: {
    HeaderBlock,
    NotificationContinueActionBlock,
    FooterBlock,
    Swiper,
    SwiperSlide,
    AuthRegBlock,
    ProductItemBlock,
    MainFeedbackBlock,
    ArticleItemBlock,
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
  data() {
    return {
      authText: "",
      isOpenAuth: false,
      nextAfterAuth: "/",
      page: {},
      banners: [],
      stories: [],
      feedbacks: [],
      articles: [],
      selections: [],
      valueAllowTouchMove: true,
      valueAllowTouchMoveStories: false,
      storiesBlockSlidesPerView: 6,
      isOpenPopupStories: false,
      initialSlide: null,
      indexStory: 0,
      indexStoryElement: 0,
      isMuted: true,
      startPause: 0,
      dateDay: new Date().getDate(),
      storiesSlidesPerView: 3,
      isVideo: false,
      bannerSlidesPerView: 1,
      bannerInitialSlide: 1,
      feedbacksSlidesPerView: 1,
      numBanner: 0,
      valueDelay: 0,
      apiUrl: "",
      locale: Locale,
    };
  },
  created() {
    this.apiUrl = Const.API_URL;
    let that = this;
    window.onblur = function () {
      that.startPause = new Date().getTime();
    };
    window.onfocus = function () {
      that.startPause = 0;
    };
    if (window.innerWidth < 760) {
      this.storiesSlidesPerView = 1;
    }
    if (window.innerWidth >= 950) {
      this.valueAllowTouchMove = false;
    }
    if (window.innerWidth < 790) {
      this.storiesBlockSlidesPerView = 2;
      this.valueAllowTouchMoveStories = true;
    } else if (window.innerWidth < 1150) {
      this.storiesBlockSlidesPerView = 4;
    }
    if (window.innerWidth < 450) {
      this.bannerSlidesPerView = window.innerWidth / 361;
      this.bannerInitialSlide = 2;
    }
    if (window.innerWidth < 790) {
      this.feedbacksSlidesPerView = window.innerWidth / 296;
    }
    document.addEventListener("keydown", function (evt) {
      if (evt.which === 27) {
        that.isOpenAuth = false;
        that.isOpenPopupStories = false;
        for (let item in that.feedbacks) {
          that.feedbacks[item].isOpen = false;
        }
        that.isOpenPopup(false);
      }
    });
    window.addEventListener("resize", function () {
      that.checkHeightStoryElement();
    });
    this.checkHeightStoryElement();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const isLogin = urlParams.get("is-login");
    const next = urlParams.get("next");
    if (isLogin) {
      this.isOpenAuth = true;
      this.authText =
        "Войдите или зарегистрируйтесь,<br /> чтобы просмотреть профиль";
      if (next) this.nextAfterAuth = next;
      history.pushState(
        null,
        null,
        location.protocol + "//" + location.host + location.pathname
      );
    }
  },
  async mounted() {
    try {
      let result = await api.shop.getShopPageFromPath("");
      result = result["data"]["results"][0];
      document.title = result["title"];
      document.getElementsByTagName("meta")["description"].content =
        result["description"];
    } catch (error) {
      console.log(error);
    }
    try {
      let page = await api.shop.getShopMainPage();
      page = page["data"]["results"][0];
      this.page.headline_h1 = page["headline_h1"];
      this.page.reviews_headline = page["reviews_headline"];
      this.page.blog_headline = page["blog_headline"];
      this.page.blog_type = page["blog_type"];
    } catch (error) {
      console.log(error);
    }
    try {
      const banners = await api.shop.getShopMainPageBanner();
      for (let item in banners["data"]["results"]) {
        item = banners["data"]["results"][item];
        let image = null;
        if (window.innerWidth < 450 && item["is_show_mobile"]) {
          image = item["mobile"]["image_object_big"];
        } else if (window.innerWidth < 950 && item["is_show_tablet"]) {
          image = item["tablet"]["image_object_big"];
        } else if (window.innerWidth > 949 && item["is_show_desktop"]) {
          image = item["desktop"]["image_object_big"];
        } else continue;
        let link = item["button_external_reference"];
        if (item["button_page"]) {
          link = "";
          if (item["button_page"]["type"] === 3) link = "/catalog";
          if (item["button_page"]["type"] === 4) link = "/blog";
          if (item["button_page"]["type"] === 5) link = "/blog";
          if (item["button_page"]["type"] === 6) link = "/catalog";
          link += "/" + item["button_page"]["url"];
        }
        this.banners.push({
          id: item["id"],
          working_seconds: item["working_seconds"] * 1000,
          link: link,
          image: image,
        });
      }
      if (this.banners.length > 0) {
        this.valueDelay = this.banners[0]["working_seconds"];
        this.timerBanner(this.numBanner, 0);
      }
    } catch (error) {
      console.log(error);
    }
    try {
      this.selections = await api.shop.getShopMainPageSelection();
      this.selections = this.selections["data"]["results"];
      for (let item in this.selections) {
        let products = this.selections[item]["products"];
        this.selections[item]["products"] = [];
        for (let product in products) {
          product = products[product]["product"];
          if (product["is_deleted"]) continue;
          this.selections[item]["products"].push(product);
        }
      }
    } catch (error) {
      console.log(error);
    }
    try {
      this.feedbacks = await api.shop.getShopMainPageReview();
      this.feedbacks = this.feedbacks["data"]["results"];
    } catch (error) {
      console.log(error);
    }
    try {
      this.articles = await api.shop.getBlogArticles();
      this.articles = this.articles["data"]["results"];
      let key = "id";
      if (this.page.blog_type === 1) key = "count_views";
      this.articles = this.articles.sort((item1, item2) =>
        item1[key] > item2[key] ? -1 : 1
      );
      this.articles = this.articles.slice(0, 6);
    } catch (error) {
      console.log(error);
    }
    try {
      let result = await api.shop.getShopMainPageStory();
      for (let item in result["data"]["results"]) {
        item = result["data"]["results"][item];
        if (item["is_show"]) this.stories.push(item);
      }
      for (let index in this.stories) {
        this.checkViewStory(index);
      }
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    timerBanner(index, timer) {
      if (index !== this.numBanner) return false;
      let that = this;
      if (this.startPause === 0) timer += 50;
      if (timer >= this.valueDelay) {
        const swiper = document.querySelector(".banners-block .swiper").swiper;
        swiper.slideNext();
        this.changeValueDelay();
      } else {
        setTimeout(function () {
          that.timerBanner(index, timer);
        }, 50);
      }
    },
    changeValueDelay() {
      let that = this;
      setTimeout(function () {
        let slide = document.querySelector(
          ".banners-block .swiper-slide-active"
        );
        if (!slide) return false;
        slide = slide.getAttribute("data-swiper-slide-index");
        that.valueDelay = that.banners[Number(slide)].working_seconds;
        that.numBanner += 1;
        that.timerBanner(that.numBanner, 0);
      }, 100);
    },
    getIndexStory() {
      let slide = document.querySelector(".stories-popup .swiper-slide-active");
      if (!slide) return false;
      slide = slide.querySelector(".story-item");
      return slide.getAttribute("data-index");
    },
    changeSlideStories() {
      let that = this;
      setTimeout(function () {
        let popup = document.querySelector(".stories-popup");
        if (!popup) return false;
        popup = popup.querySelectorAll(".elements");
        for (let i = 0; i < popup.length; i++) {
          popup[i].innerHTML = "";
        }
        let index = that.getIndexStory();
        if (!index) return false;
        let count = 0;
        let allCount = that.stories[index].elements.length;
        if (allCount === 0) return false;
        that.indexStory = index;
        that.indexStoryElement = 0;
        for (let element in that.stories[index].elements) {
          let obj = that.stories[index].elements[element];
          let view = localStorage.getItem("story_element_" + obj.id);
          if (Number(view) === that.dateDay) count += 1;
          else {
            that.indexStoryElement = element;
            break;
          }
        }
        if (count === allCount) {
          for (let element in that.stories[index].elements) {
            let obj = that.stories[index].elements[element];
            localStorage.removeItem("story_element_" + obj.id);
          }
        }
        that.insertStoryElement(index);
      }, 100);
      setTimeout(function () {
        let clickPrev = document.querySelector(
          ".stories-popup .swiper-slide-prev"
        );
        let clickNext = document.querySelector(
          ".stories-popup .swiper-slide-next"
        );
        const swiper = document.querySelector(".stories-popup .swiper").swiper;
        if (clickPrev) {
          clickPrev.onclick = function () {
            swiper.slidePrev();
          };
        }
        if (clickNext) {
          clickNext.onclick = function () {
            swiper.slideNext();
          };
        }
      }, 1000);
    },
    clearStoryElements() {
      let slide = document.querySelector(".stories-popup");
      slide = slide.querySelector(".swiper-slide-active");
      slide = slide.querySelector(".elements");
      slide.innerHTML = "";
    },
    insertStoryElement(index) {
      let element = this.indexStoryElement;
      let story = this.stories[index];
      let obj = story.elements[element];
      if (!obj) {
        this.isOpenPopupStories = null;
        return false;
      }
      let file = obj.file.file_object;
      let format = file.split(".");
      format = format[format.length - 1].toLowerCase();
      this.clearStoryElements();
      let slide = document.querySelector(".stories-popup");
      slide = slide.querySelector(".swiper-slide-active");
      slide = slide.querySelector(".elements");
      let loadDiv = document.createElement("div");
      loadDiv.classList.add("pre-load");
      loadDiv.innerHTML =
        "<img src='" + this.apiUrl + "static/images/shop-loading.svg'/>";
      slide.appendChild(loadDiv);
      let newDiv = document.createElement("div");
      newDiv.classList.add("element-item");
      newDiv.classList.add("load");
      let htmlValue =
        "<div class='title'><span class='image' style='background-image: url(" +
        story.preview.image_object_small +
        ")'></span><span class='name' style='color: " +
        obj.text_color +
        "'>" +
        story.name +
        "</span></div>";
      let typeElement = 1;
      if (format === "mov" || format === "mp4") {
        htmlValue +=
          "<video webkit-playsinline playsinline muted='" +
          this.isMuted +
          "' autoPlay><source type='video/mp4' src='" +
          file +
          "' /></video>";
        this.isVideo = obj.is_control_mute;
        typeElement = 2;
      } else {
        htmlValue += "<img src='" + obj.file.file_object_big + "' />";
        this.isVideo = false;
      }
      let dopClass = "";
      if (!this.isVideo) dopClass = "not-mute ";
      if (obj.button_page) {
        let url = "";
        if (obj.button_page.type === 3) url = "/catalog";
        if (obj.button_page.type === 4) url = "/blog";
        if (obj.button_page.type === 5) url = "/blog";
        if (obj.button_page.type === 6) url = "/catalog";
        url += "/" + obj.button_page.url;
        htmlValue +=
          "<a href='" +
          url +
          "' class='" +
          dopClass +
          "story-button-" +
          obj.id +
          "'>" +
          obj.button_text +
          "</a>";
      } else if (obj.button_external_reference !== "") {
        htmlValue +=
          "<a href='" +
          obj.button_external_reference +
          "' class='" +
          dopClass +
          "story-button-" +
          obj.id +
          "'>" +
          obj.button_text +
          "</a>";
      }
      newDiv.innerHTML = htmlValue;
      slide.appendChild(newDiv);
      let block = slide.querySelector(".element-item");
      if (typeElement === 1) {
        let image = block.querySelector("img");
        image.onload = function () {
          block.classList.remove("load");
          slide.querySelector(".pre-load").remove();
        };
      } else {
        let video = block.querySelector("video");
        video.oncanplay = function () {
          block.classList.remove("load");
          slide.querySelector(".pre-load").remove();
          if (!this.isMuted && video) video.muted = false;
          video.play();
        };
      }
      this.resetStoryTab();
      this.timerStoryElement(index, element, obj, 0);
      localStorage.setItem("story_element_" + obj.id, this.dateDay);
      this.checkViewStory(index);
      this.checkHeightStoryElement();
    },
    clickNextStoryElement(event, isNext = false) {
      let index = this.getIndexStory();
      this.indexStoryElement = Number(this.indexStoryElement);
      let element = document.querySelector(".stories-popup");
      if (isNext) {
        this.indexStoryElement = this.indexStoryElement + 1;
      } else {
        let width = element.offsetWidth / 2;
        let widthLeft = width - 100;
        let widthRight = width + 100;
        if (event.pageX > widthRight) {
          this.indexStoryElement = this.indexStoryElement + 1;
          isNext = true;
        } else if (event.pageX < widthLeft) {
          this.indexStoryElement = this.indexStoryElement - 1;
          isNext = true;
        } else return false;
      }
      let maxCount = this.stories[index].elements.length;
      const swiper = document.querySelector(".stories-popup .swiper").swiper;
      let blockPrev = element.querySelector(".swiper-slide-prev");
      let blockNext = element.querySelector(".swiper-slide-next");
      if (!blockPrev && !isNext) {
        this.indexStoryElement = 0;
        this.insertStoryElement(index);
      } else if (this.indexStoryElement < 0) {
        this.clearStoryElements();
        this.indexStoryElement = 0;
        swiper.slidePrev();
      } else if (this.indexStoryElement >= maxCount && blockNext) {
        this.clearStoryElements();
        this.indexStoryElement = maxCount - 1;
        swiper.slideNext();
      } else this.insertStoryElement(index);
    },
    timerStoryElement(index, element, obj, timer) {
      if (index !== this.indexStory) return false;
      if (element !== this.indexStoryElement) return false;
      let working_seconds = obj.working_seconds;
      if (working_seconds === 0) working_seconds = 7;
      working_seconds = working_seconds * 1000;
      let blocks = document.querySelector(".stories-popup");
      blocks = blocks.querySelector(".swiper-slide-active .tabs");
      blocks = blocks.querySelectorAll("div");
      if (!blocks[element]) return false;
      let block = blocks[element].querySelector("span");
      let percent = 0;
      if (timer > 0) percent = (timer * 100) / working_seconds;
      block.style.width = percent + "%";
      let that = this;
      if (this.startPause === 0) timer += 50;
      if (timer >= working_seconds) {
        that.clickNextStoryElement(null, true);
      } else {
        setTimeout(function () {
          that.timerStoryElement(index, element, obj, timer);
        }, 50);
      }
    },
    startPauseStoryElement() {
      this.startPause = new Date().getTime();
      let video = document.querySelector("video");
      if (!video) return false;
      video.pause();
    },
    stopPauseStoryElement(event) {
      let time = new Date().getTime() - 200;
      let video = document.querySelector("video");
      if (video) video.play();
      if (this.startPause > time) this.clickNextStoryElement(event);
      this.startPause = 0;
    },
    resetStoryTab() {
      let blocks = document.querySelector(".stories-popup");
      blocks = blocks.querySelectorAll(".swiper-slide-active .tabs div");
      for (let i = 0; i < blocks.length; i++) {
        let block = blocks[i].querySelector("span");
        if (i < this.indexStoryElement) block.style.width = "100%";
        else if (i > this.indexStoryElement) block.style.width = "0%";
      }
    },
    checkViewStory(index) {
      let count = 0;
      let allCount = this.stories[index].elements.length;
      for (let element in this.stories[index].elements) {
        let obj = this.stories[index].elements[element];
        let view = localStorage.getItem("story_element_" + obj.id);
        if (Number(view) === this.dateDay) count += 1;
      }
      if (count === allCount) this.stories[index].isViewed = true;
      else this.stories[index].isViewed = false;
    },
    checkHeightStoryElement() {
      if (window.innerWidth > 760 && window.innerWidth < 1250) {
        let block = document.querySelector(".stories-popup");
        if (!block) return false;
        block = block.querySelector(".swiper-slide-active .story-item");
        if (!block) return false;
        let result = (block.offsetWidth * 100) / 620;
        result = (1080 * result) / 100;
        block.style.height = result + "px";
      }
    },
  },
  watch: {
    isOpenAuth(isOpen) {
      this.isOpenPopup(isOpen);
    },
    isOpenPopupStories(value) {
      this.isOpenPopup(value);
      if (!value) {
        this.indexStory = 0;
        this.indexStoryElement = 0;
        return false;
      }
      let that = this;
      setTimeout(function () {
        if (that.initialSlide === 0) that.changeSlideStories();
      }, 200);
    },
    isMuted(value) {
      let video = document.querySelector("video");
      if (!video) return false;
      video.muted = value;
    },
  },
};
</script>
