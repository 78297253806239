<template>
  <HeaderBlock :notNav="true" />
  <section class="article-section page-section">
    <a class="page-back" href="/blog">
      <span>{{ locale.TEXTS.backArticle }}</span>
    </a>
    <article>
      <div class="fixed-panel">
        <div>
          <img src="@/assets/images/shop-views.svg" />
          {{ countViews }}
          <span>{{ locale.TEXTS.countViewsText }}</span>
        </div>
        <div
          v-if="locale.LOCALE === 'ru'"
          @click="clickArticleLike"
          :class="{ active: isMyLike }"
        >
          <img v-if="isMyLike" src="@/assets/images/shop-like-active.svg" />
          <img v-else src="@/assets/images/shop-like-gray.svg" />
          {{ countLikes }}
          <span>{{ locale.TEXTS.countLikesText }}</span>
        </div>
        <div v-else>
          <img src="@/assets/images/shop-like-gray.svg" />
          {{ countLikes }}
          <span>{{ locale.TEXTS.countLikesText }}</span>
        </div>
        <div>
          <img src="@/assets/images/shop-comments.svg" />
          0
          <span>{{ locale.TEXTS.countCommentsText }}</span>
        </div>
        <div class="share" @click="clickShare">
          <img src="@/assets/images/shop-shared.svg" />
          Share
        </div>
        <hr />
        <a
          v-if="locale.LOCALE === 'ru'"
          :href="
            'https://vk.com/share.php?url=https://ultrabalance.ru/blog/' + slug
          "
          target="_blank"
        >
          <img src="@/assets/images/shop-vk.svg" />
          <span>Поделиться в вк</span>
        </a>
        <a
          v-else
          :href="
            'https://www.facebook.com/sharer/sharer.php?u=https://ultrabalance.ru/blog/' +
            slug
          "
          target="_blank"
        >
          <img src="@/assets/images/shop-facebook.png" height="18" />
          <span>Share on VK</span>
        </a>
        <a
          :href="
            'https://t.me/share/url?url=https://ultrabalance.ru/blog/' +
            slug +
            '&amp;text=' +
            article.headline_h1
          "
          target="_blank"
        >
          <img src="@/assets/images/shop-telegram.svg" />
          <span>{{ locale.TEXTS.shareTelegram }}</span>
        </a>
      </div>
      <EditorBlock
        v-if="article"
        :page="article"
        :isProduct="false"
        :sourcePage="5"
      />
      <div class="articles-recommended" v-if="article.articles.length > 0">
        <h5>{{ locale.TEXTS.articleRecommended }}</h5>
        <a
          v-for="item in article.articles"
          :key="item"
          :href="'/blog/' + item.shop_page[0].url"
          class="item"
        >
          <span
            v-if="item.banner"
            class="image"
            :style="{
              background: 'url(' + item.banner.image_object_big + ')',
            }"
          ></span>
          <span class="text">
            <span v-if="item.categories.length > 0" class="category">
              {{ textCategories(item.categories) }}
            </span>
            <span class="name">{{ item.headline_h1 }}</span>
          </span>
        </a>
      </div>
    </article>
  </section>
  <FooterBlock />
  <AuthRegBlock
    v-if="isOpenAuth && locale.LOCALE === 'ru'"
    :authText="authText"
  />
</template>

<script>
import pageMixin from "@/mixins/pageMixin";
import HeaderBlock from "@/components/HeaderBlock.vue";
import EditorBlock from "@/components/EditorBlock.vue";
import FooterBlock from "@/components/FooterBlock.vue";
import AuthRegBlock from "@/components/AuthRegBlock.vue";
import api from "@/api";
import Locale from "@/api/locale";

export default {
  mixins: [pageMixin],
  components: {
    HeaderBlock,
    EditorBlock,
    FooterBlock,
    AuthRegBlock,
  },
  props: {
    article: Object,
    slug: String,
    authToken: String,
    profile: Object,
  },
  data() {
    return {
      countViews: 0,
      countLikes: 0,
      isOpenAuth: false,
      authText: "",
      isMyLike: false,
      locale: Locale,
    };
  },
  async mounted() {
    this.countViews = this.article.count_views;
    this.countLikes = this.article.count_likes;
    let name = "article_" + this.article.id;
    if (!sessionStorage.getItem(name)) {
      sessionStorage.setItem(name, "1");
      await api.shop.postBlogArticleCountViews(this.article.id);
      this.countViews += 1;
    }
    let blocks = document.querySelector(".blocks");
    let panel = document.querySelector(".fixed-panel");
    if (window.innerWidth >= 900) {
      panel.style.top = blocks.offsetTop + "px";
      panel.classList.add("static");
      let offsetTop = blocks.offsetTop - 200;
      window.addEventListener("scroll", function () {
        let height = blocks.offsetHeight + blocks.offsetTop;
        let heightY = height + (window.innerHeight - panel.offsetHeight - 200);
        let scrollY = window.innerHeight + window.scrollY;
        if (scrollY > heightY) {
          if (!panel.classList.contains("static")) {
            height = height - panel.offsetHeight;
            panel.style.top = height + "px";
          }
          panel.classList.add("static");
        } else if (window.scrollY > offsetTop) {
          if (panel.classList.contains("static")) {
            panel.classList.remove("static");
          }
          panel.style.top = "200px";
        } else {
          panel.style.top = blocks.offsetTop + "px";
          panel.classList.add("static");
        }
      });
    } else {
      window.addEventListener("scroll", function () {
        let height = blocks.offsetHeight + blocks.offsetTop;
        let scrollY = window.innerHeight + window.scrollY - 100;
        if (scrollY > height) panel.classList.add("static");
        else if (panel.classList.contains("static")) {
          panel.classList.remove("static");
        }
      });
    }
    if (this.authToken) {
      if (this.article.user_likes.includes(this.profile.id)) {
        this.isMyLike = true;
      }
    }
  },
  methods: {
    textCategories(categories) {
      let text = "";
      for (let item in categories) {
        if (text !== "") text += ", ";
        text += categories[item]["name"];
      }
      return text;
    },
    async clickArticleLike() {
      if (this.authToken) {
        try {
          const result = await api.shop.putShopArticleUserLike(this.article.id);
          this.countLikes = result["data"]["count_likes"];
          if (this.isMyLike) this.isMyLike = false;
          else this.isMyLike = true;
        } catch (error) {
          console.log(error);
        }
      } else {
        this.isOpenAuth = true;
        this.authText =
          "Войдите или зарегистрируйтесь,<br /> чтобы оценить публикацию";
      }
    },
  },
};
</script>
